import React from 'react'
import './FieldSet_styles.scss'

const FieldSet = ({ label, children, additionalClasses = '' }) => (
  <fieldset className={`FieldSet ${additionalClasses}`}>
    <legend> {label} </legend>
    {children}
  </fieldset>
)

export default FieldSet

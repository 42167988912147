import React, { useState, useEffect } from 'react'
import ky from 'ky'
import './BackstageNav.scss'

export const BackstageManager = () => {
  const [backstageNavInfo, setBackstageNavInfo] = useState(null)

  // Fetch user information on initial render
  useEffect(() => {
    ky.get('/users/backstage_nav_info', { credentials: 'same-origin' })
      .json()
      .then((navInfo) => setBackstageNavInfo(navInfo))
  }, [])

  return backstageNavInfo && backstageNavInfo.adminLinks ? (
    <BackstageNav
      adminLinks={backstageNavInfo.adminLinks}
      userTime={backstageNavInfo.userTime}
      utcTime={backstageNavInfo.utcTime}
    />
  ) : null
}

// returns the category who contains a sublink to the current path
const matchCurrentPathToCategory = (adminLinks, currentPath) => {
  if (currentPath === '/admin') return 0

  let matchedCategory = 0
  adminLinks.forEach((categoryArray, categoryIndex) => {
    adminLinks[categoryIndex][1].forEach((link) => {
      if (currentPath.startsWith(link[1])) matchedCategory = categoryIndex
    })
  })

  return matchedCategory
}

// returns the sublink to the current path
const matchCurrentPathToSublink = (adminLinks, currentPath) => {
  if (currentPath === '/admin') return 0
  let matchedSublink = -1
  adminLinks.forEach((categoryArray, categoryIndex) => {
    adminLinks[categoryIndex][1].forEach((link, sublinkIndex) => {
      if (currentPath.startsWith(link[1])) matchedSublink = sublinkIndex
    })
  })

  return matchedSublink
}

export const BackstageNav = ({
  adminLinks, // a labrynthine object structure, godspeed theseus
  userTime,
  utcTime,
}) => {
  const currentPath = window.location.pathname
  const matchedCategory = matchCurrentPathToCategory(adminLinks, currentPath)
  const matchedSublink = matchCurrentPathToSublink(adminLinks, currentPath)

  const [category, setCategory] = useState(matchedCategory)

  return (
    <nav className="BackstageNav" id="BackstageNav">
      <ul className="categories">
        <li>
          <a href="/"> Home </a>
        </li>

        {adminLinks.map((categoryArray, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={() => setCategory(i)}
                className={category === i ? 'selected-category' : ''}
              >
                {adminLinks[i][0]}
              </a>
            </li>
          )
        })}
      </ul>

      <ul className="sub-categories">
        {adminLinks[category][1].map((link, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              <a
                href={link[1]}
                className={
                  matchedCategory === category && matchedSublink === i
                    ? 'selected-sublink'
                    : ''
                }
              >
                {link[0]}
              </a>
            </li>
          )
        })}
      </ul>

      <div className="clock">
        <div>{userTime}</div>
        <div>{utcTime}</div>
      </div>
    </nav>
  )
}

export default BackstageManager

import { CookieStorage, isSupported } from 'local-storage-fallback'
import Cookies from 'js-cookie'

let storageSingleton

export const preferredStorage = () => {
  if (storageSingleton === undefined) {
    if (isSupported('sessionStorage')) {
      storageSingleton = window.sessionStorage
    } else if (isSupported('cookieStorage')) {
      storageSingleton = new CookieStorage()
    } else {
      storageSingleton = null
    }
  }
  return storageSingleton
}

export const getSessionStorageObject = (key) => {
  let result = null

  try {
    result = JSON.parse(preferredStorage().getItem(key))
  } catch (err) {
    if (!(err instanceof SyntaxError)) throw err
  }

  return result
}

export const setSessionStorageObject = (key, value) => {
  if (preferredStorage()) {
    preferredStorage().setItem(key, JSON.stringify(value))
    return true
  }
  return false
}

/* Like fetch, but handle some network level errors.
   Network errors and non-200 HTTP responses reject the promise.
   200 status responses will resolve the promise to parsed JSON.

   Usage:
     state = {
       errorMessage: null, // or a string
       isWaitingForServerChecking: false,
     }

     fetchJsonWithErrorHandling('url', fetchOptions, this)
       .then(resultObject => { ...use the result... })
       .catch(() => null);
*/
export const fetchJsonWithErrorHandling = (
  url,
  context,
  options = {},
  _fetch = fetch
) => {
  const loggingPrefix = `fetchJsonWithErrorHandling() ${
    (options && options.method) || 'GET'
  } ${url}`
  context.setState({
    errorMessage: null,
    isWaitingForServerChecking: true,
  })

  console.debug(loggingPrefix, 'contacting...')
  const promise = _fetch(url, options)
    .then((res) => {
      // server has responded, but maybe with HTTP error
      if (!res.ok)
        throw new Error(`HTTP ${res.status} - ${res.statusText} from ${url}`)

      console.debug(loggingPrefix, 'received.')

      context.setState({ isWaitingForServerChecking: false })
      return res.json() // server responded!
    })
    .catch((exception) => {
      // HTTP errors above, plus other network errors
      console.warn(loggingPrefix, exception)
      context.setState({
        errorMessage: exception.message,
        isWaitingForServerChecking: false,
      })
      return Promise.reject(exception)
    })

  return promise
}

// addDollarSpaceCommasToNumber(1000333.12345) -> '1,000,333.123'
export const addDollarSpaceCommasToNumber = (num) =>
  Number(num).toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
  })

export const areTwoObjectsShallowlyEquivalent = (a, b) => {
  if (a === b) return true
  if (!a || !b) return false
  const aProps = Object.getOwnPropertyNames(a)
  const bProps = Object.getOwnPropertyNames(b)

  if (aProps.length !== bProps.length) return false

  for (let i = 0; i < aProps.length; i += 1) {
    const propName = aProps[i]

    if (a[propName] !== b[propName]) return false
  }
  return true
}

/*
 * format('hi ${user}', {user: 'Olive'}) --> 'hi Olive'
 */
export const format = (string, variables) => {
  let result = string
  Object.entries(variables).forEach((entry) => {
    const [key, value] = entry
    const replacer_str = `\${${key}}`
    result = result.replace(replacer_str, value)
  })

  return result
}

export const getCSRFToken = () => {
  window.Cookies = Cookies
  const token = Cookies.get('X-CSRF-Token')
  return token
}

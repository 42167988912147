import React, { useEffect } from 'react'
import iconX from './icon_x.svg'
import './FullScreenModal.scss'

export const isIOSSafari =
  (!!window.navigator.userAgent.match(/iPad/i) ||
    !!window.navigator.userAgent.match(/iPhone/i)) &&
  !!window.navigator.userAgent.match(/WebKit/i) &&
  !window.navigator.userAgent.match(/CriOS/i)

const FullScreenModal = ({
  setIsModalOpen,
  closeText,
  children,
  header = '',
  additionalCloseHandler = null,
  showX = true,
  scrollingRef = null,
  id = null,
}) => {
  useEffect(() => {
    const isDesktop = window.innerWidth > 849

    /* [Chi notes + Annette notes]:
      Reasons desktop and mobile have differnt logic:
      1. adding overflow:hidden on desktop will cause a jump to the top of the page
      2. adding position: fixed on desktop will stop background scrolling, but not modal
    */
    if (isDesktop) document.body.style.position = 'fixed'
    else document.body.style.overflow = 'hidden'

    return () => {
      if (isDesktop) document.body.style.position = 'initial'
      else document.body.style.overflow = 'initial'
    }
  }, [])

  return (
    <div className="FullScreenModal" id={id}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="modal-overlay"
        onWheel={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        onClick={(e) => {
          setIsModalOpen(false)
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className="full-screen-modal"
          onWheel={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
          onClick={(e) => {
            e.stopPropagation()
          }}
          ref={scrollingRef}
        >
          <div
            className={`modal-content ${
              isIOSSafari ? 'extra-bottom-margin' : ''
            }`}
          >
            {header && (
              <div className="title-container">
                <h2>{header}</h2>
                {showX && (
                  <button
                    className="close-x-button"
                    type="button"
                    onClick={(e) => {
                      setIsModalOpen(false)
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') setIsModalOpen(false)
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  >
                    <img src={iconX} alt={iconX} />
                  </button>
                )}
              </div>
            )}
            {children}
          </div>
          <div className="close-text-container">
            <button
              className="close-text"
              type="button"
              onClick={(e) => {
                setIsModalOpen(false)
                if (additionalCloseHandler) additionalCloseHandler()
                e.stopPropagation()
                e.preventDefault()
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') setIsModalOpen(false)
                if (additionalCloseHandler) additionalCloseHandler()
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              {closeText}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullScreenModal

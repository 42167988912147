import React, { Suspense } from 'react'

// importFunction is called when this component is first rendered
// For deferring loading of JS packs to improve PageSpeed
const withLazyLoading = (importFunction, fallbackClass = '') =>
  // eslint-disable-next-line func-names
  function (props) {
    const LazyComponent = React.lazy(importFunction)
    const fallbackElement = <div className={fallbackClass} />

    return (
      <Suspense fallback={fallbackElement}>
        <LazyComponent {...props} />
      </Suspense>
    )
  }

export default withLazyLoading

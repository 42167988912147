/*
   Render flash messages stored in cookie.  See ApplicationController#write_flash_to_cookie

   Inputs:
      cookies['flash'] = a JSON encoded object such as:
        { "notice": ["Here is note"],
          "error": [ "title (en) is missing", "description (es) is too short" ]
        }

   This is needed because our cached pages cannot show flash messages. We tried
   using turbo-frames but that had problems too.

   Here's how to test the flash for all our different layouts:
    - twentyone
        turn on caching: 'rails dev:cache'
        samples: http://localhost:3000/?flash=notice&flash_warn=warning&flash_error=error
        actual error: http://localhost:3000/features/9999

    - desktop
        samples: http://localhost:3000/users/new?flash=notice&flash_warn=warning&flash_error=error
        actual error: http://localhost:3000/questions/9999

    - mobile
        change browser mode, then visit
        samples: http://localhost:3000/users/new?flash=notice&flash_warn=warning&flash_error=error

    - admin
       samples: http://localhost:3000/twentyone/admin/features?flash=notice&flash_warn=warning&flash_error=error
       acutal error: admin > features > create new > save

 */
import React from 'react'
import Cookie from 'js-cookie'
import './FlashFromCookie.scss'

export const FlashFromCookie = () => {
  if (!Cookie.get('flash')) return null // nothing to display

  // Extract the messages from a cookie
  const decoded = Cookie.get('flash')?.replaceAll('+', ' ')
  const message_hash = JSON.parse(decoded)
  Cookie.remove('flash')

  return (
    <div className="FlashFromCookie">
      {Object.entries(message_hash).map(([type, messages]) =>
        messages.map((message, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`type${i}`} className={`flash ${type}`}>
            {message}
          </div>
        ))
      )}
    </div>
  )
}

/*
  This line will show a diagram of JS packs and chunks:
    NODE_ENV=production bin/webpacker --profile --json > /tmp/stats.json ; npx webpack-bundle-analyzer /tmp/stats.json
 */

import 'regenerator-runtime/runtime.js'

import { Turbo } from '@hotwired/turbo-rails'

import ReactOnRails from 'react-on-rails'
import withLazyLoading from '../components/shared_components/withLazyLoading'

// Global navigation
import DesktopUserStatus from '../components/accounts/DesktopUserStatus'
import LocaleSwitcher from '../components/accounts/LocaleSwitcher'
import { BackstageManager } from '../components/admin/backstage_nav/BackstageNav'
import { FlashFromCookie } from '../components/shared_components/FlashFromCookie'

Turbo.session.drive = false

// admin
const ChartWithThresholds = withLazyLoading(() =>
  import(
    /* webpackChunkName:"admin" */ '../components/admin/ChartWithThresholds/ChartWithThresholds'
  )
)
const FeatureEdit = withLazyLoading(() =>
  import(
    /* webpackChunkName:"admin" */ '../components/admin/features/FeatureEdit'
  )
)
const QuizQuestionWidget = withLazyLoading(() =>
  import(
    /* webpackChunkName:"admin" */ '../components/admin/quiz_question_widget/QuizQuestionWidget'
  )
)

const HowToEditor = withLazyLoading(() =>
  import(
    /* webpackChunkName:"admin" */ '../components/admin/methods/HowToEditor'
  )
)

// accounts
const SignInForm = withLazyLoading(() =>
  import(/* webpackChunkName:"accounts" */ '../components/accounts/SignInForm')
)
const SignUpForm = withLazyLoading(() =>
  import(/* webpackChunkName:"accounts" */ '../components/accounts/SignUpForm')
)
const PasswordForm = withLazyLoading(() =>
  import(
    /* webpackChunkName:"accounts" */ '../components/accounts/PasswordForm'
  )
)

// Clinics
const LazyClinicStateMap = withLazyLoading(() =>
  import(
    /* webpackChunkName:"ClinicStateMap" */ '../components/bc_map/ClinicStateMap'
  )
)
const ClinicFinder = withLazyLoading(() =>
  import(
    /* webpackChunkName:"clinicfinder" */ '../components/ClinicFinder/ClinicFinder'
  )
)
const BCSuppliers = withLazyLoading(() =>
  import(
    /* webpackChunkName:"clinicfinder" */ '../components/BCSuppliers/BCSuppliers'
  )
)
const AbortionFinderForm = withLazyLoading(() =>
  import(
    /* webpackChunkName:"clinicfinder" */ '../components/AbortionFinder/AbortionFinderForm'
  )
)
const ServicesOfferedTabs = withLazyLoading(() =>
  import(
    /* webpackChunkName:"clinicfinder" */ '../components/ClinicDetails/ServicesOfferedTabs'
  )
)
const GoogleMapBox = withLazyLoading(() =>
  import(
    /* webpackChunkName:"clinicfinder" */ '../components/ClinicDetails/GoogleMapBox'
  )
)

// BCBenefits
const Qualification = withLazyLoading(() =>
  import(
    /* webpackChunkName:"bcbenefits" */ '../components/bcbenefits/Qualification/Qualification'
  )
)
const Verification = withLazyLoading(() =>
  import(
    /* webpackChunkName:"bcbenefits" */ '../components/bcbenefits/Verification/Verification'
  )
)
const ZipQualifier = withLazyLoading(() =>
  import(
    /* webpackChunkName:"bcbenefits" */ '../components/bcbenefits/ZipQualifier/ZipQualifier'
  )
)
const ReceiptsFlow = withLazyLoading(() =>
  import(
    /* webpackChunkName:"bcbenefits" */ '../components/receipts_flow/ReceiptsFlow'
  )
)

// Methods
const MethodExplorer = withLazyLoading(
  () =>
    import(
      /* webpackChunkName:"methods" */ '../components/method_explorer/MethodExplorer'
    ),
  'MethodExplorer-skeleton'
)
const InfoTooltip = withLazyLoading(() =>
  import(
    /* webpackChunkName:"methods" */ '../components/shared_components/InfoTooltip/InfoTooltip'
  )
)
const SideBySide = withLazyLoading(() =>
  import(
    /* webpackChunkName:"methods" */ '../components/side_by_side/SideBySide'
  )
)

// Other
const InfoPopupEmbeddable = withLazyLoading(() =>
  import(
    /* webpackChunkName:"other" */ '../components/bcbenefits/shared/info_popup/InfoPopupEmbeddable'
  )
)
const MobileItemList = withLazyLoading(() =>
  import(
    /* webpackChunkName:"other" */ '../components/mobile_item_list/MobileItemList'
  )
)
const QuizQuestion = withLazyLoading(() =>
  import(
    /* webpackChunkName:"other" */ '../components/quiz_questions/QuizQuestion'
  )
)
const DisqusComments = withLazyLoading(() =>
  import(
    /* webpackChunkName:"other" */ '../components/disqus_comments/DisqusComments'
  )
)
const FeedbackVotes = withLazyLoading(() =>
  import(/* webpackChunkName:"other" */ '../components/features/FeedbackVotes')
)

ReactOnRails.register({
  BackstageManager,
  DesktopUserStatus,
  LocaleSwitcher,
  FlashFromCookie,

  ChartWithThresholds,
  FeatureEdit,
  QuizQuestionWidget,
  HowToEditor,

  SignInForm,
  SignUpForm,
  PasswordForm,

  LazyClinicStateMap,
  BCSuppliers,
  ClinicFinder,
  AbortionFinderForm,
  ServicesOfferedTabs,
  GoogleMapBox,

  Qualification,
  Verification,
  ZipQualifier,
  ReceiptsFlow,

  MethodExplorer,
  InfoTooltip,
  SideBySide,

  InfoPopupEmbeddable,
  MobileItemList,
  QuizQuestion,
  DisqusComments,
  FeedbackVotes,
})

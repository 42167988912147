import React from 'react'
import FieldSet from '../shared_components/field_set/FieldSet'
import { locale } from './helpers'
import { SignInForm as text } from './accounts.json'
import './SignUpOrIn_styles.scss'

export const SignInForm = ({
  is_skipping_instruction,
  csrf_token,
  errorString = '',
  email = '',
}) => {
  const error = JSON.parse(errorString || '{}')

  return (
    <div className="SignInForm" id="SignInForm">
      <p>{text[locale].sign_in_using_your_account}</p>
      {is_skipping_instruction || (
        <p className="action">
          {`${text[locale].new_to_bedsider} `}
          <a href={`${locale === 'es' ? '/es' : ''}/users/new`}>
            {text[locale].sign_up_here}
          </a>
        </p>
      )}
      <form
        action="/user_sessions"
        method="post"
        acceptCharset="UTF-8"
        className="new_user_session"
      >
        <FieldSet
          label={text[locale].email}
          additionalClasses={error.email && 'fieldset_with_error'}
        >
          <input
            type="email"
            name="user_session[email]"
            id="user_session_email"
            defaultValue={email}
            required
          />
        </FieldSet>
        {error.email && <div className="error">{error.email.join(', ')}</div>}

        <FieldSet
          label={text[locale].password}
          additionalClasses={error.password && 'fieldset_with_error'}
        >
          <input
            type="password"
            name="user_session[password]"
            id="user_session_password"
            required
          />
        </FieldSet>
        {error.password && (
          <div className="error">{error.password.join(', ')}</div>
        )}

        <p className="action">
          <a href="/password_resets/new">{text[locale].reset_password}</a>
        </p>
        <input type="hidden" name="authenticity_token" value={csrf_token} />
        <input
          className="green_button"
          type="submit"
          value={text[locale].sign_in}
        />
      </form>
    </div>
  )
}

export default SignInForm
